import React from "react"

import { Form, Formik } from "formik"
import * as Yup from "yup"

import Button from "@kiwicom/orbit-components/lib/Button"
import Stack from "@kiwicom/orbit-components/lib/Stack"

import {
  EmberModal,
  EmberModalHeader,
  EmberModalSection,
} from "components/generic/ember-modal"
import ValidatedInputField from "components/validated-input-field"

interface PasswordSetModalSettings {
  handlePasswordSet: (string: string) => void
  fullScreen: boolean
}

const PasswordSetModal = ({
  handlePasswordSet,
  fullScreen,
}: PasswordSetModalSettings) => {
  return (
    <EmberModal
      size="small"
      isMobileFullPage={fullScreen}
      fullScreen={fullScreen}
    >
      <EmberModalHeader
        title="Enter a new password"
        description="Your password must be at least 8 characters long and contain at least one lowercase letter."
      />
      <EmberModalSection>
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .required("Please enter a new password")
              .min(8, "Your password must be at least 8 characters long")
              .matches(
                /^.*[a-z]+.*/,
                "Your password must contain at least one lowercase letter"
              ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handlePasswordSet(values.password)
            setSubmitting(false)
          }}
        >
          {(props) => (
            <Form>
              <Stack spacing="large">
                <ValidatedInputField name="password" type="password" />
                <Button
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  fullWidth={true}
                  submit={true}
                >
                  Set password
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </EmberModalSection>
    </EmberModal>
  )
}

export default PasswordSetModal
