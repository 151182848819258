// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { Auth } from "@aws-amplify/auth/lib/Auth"
import { navigate } from "@reach/router"
import styled from "styled-components"

import Loading from "@kiwicom/orbit-components/lib/Loading"

import Layout from "components/layout"
import LoginModal from "components/login-form"
import PasswordSetModal from "components/password-set-modal"

import { useGlobalDispatch } from "utils/state-utils"
import { paramIsSetToTrue } from "utils/string-utils"

const PageLoaderWrapper = styled.div`
  margin: auto;
`

export default () => {
  const [params, setParams] = useState(new URLSearchParams(""))
  const [promptForPassword, setPromptForPassword] = useState(false)
  const [tryLogin, setTryLogin] = useState(true)
  const [loginFailed, setLoginFailed] = useState(false)
  const redirectUrl = params.get("redirect")

  const dispatch = useGlobalDispatch()

  function handlePasswordSet(password: string) {
    setPromptForPassword(false)
    finishSignIn(password)
  }

  useEffect(() => {
    setParams(new URLSearchParams(window.location.search))
    if (params.has("username") && tryLogin) {
      setTryLogin(false)
      if (paramIsSetToTrue(params.get("prompt_for_password"))) {
        setPromptForPassword(true)
      } else {
        finishSignIn()
      }
    }
  }, [tryLogin, params.has("username")])

  const navigateToRedirectUrl = (redirectUrl) => {
    let url = redirectUrl
    if (url && (url.includes("signup") || url.includes("login"))) {
      url = null
    }

    navigate(url ? decodeURIComponent(url) : "/account/")
  }

  const finishSignIn = async (password?: string) => {
    try {
      const user = await Auth.signIn(params.get("username"))
      await Auth.sendCustomChallengeAnswer(user, params.get("code"), {
        password,
      })
      dispatch({ shouldRequestAccount: true })
      navigateToRedirectUrl(redirectUrl)
    } catch (e) {
      const softFailure = params.get("soft_failure")
      if (softFailure) {
        navigateToRedirectUrl(redirectUrl)
      } else {
        setLoginFailed(true)
      }
    }
  }

  return (
    <Layout title="Login" hideContent={true}>
      {params.has("username") && params.has("code") ? (
        loginFailed ? (
          <LoginModal
            fullScreen
            title="Sorry, your log in failed"
            description="Try requesting a new log in link. Note that links can only be used once and expire after a short period so make sure you are clicking the latest email."
          />
        ) : promptForPassword ? (
          <PasswordSetModal fullScreen handlePasswordSet={handlePasswordSet} />
        ) : (
          <PageLoaderWrapper>
            <Loading type="pageLoader" text="Logging in" />
          </PageLoaderWrapper>
        )
      ) : (
        <LoginModal
          fullScreen
          allowPassword
          onLogIn={() => navigateToRedirectUrl(redirectUrl)}
        />
      )}
    </Layout>
  )
}
